.page-wrotevote {
    background-color: $blue;

    &--bottom {
        background-position: bottom center;
        background-size: 800px;
        background-repeat: no-repeat;
    }

    & > * {
        background-color: $blue;
        color: white;
    }

    a {
        color: white;
        font-size: 14px;
        line-height: 24px;
        text-decoration: underline;
        transition: all 300ms;

        &:hover,
        &:focus,
        &:active {
            color: $primary;
        }
    }

    p {
        line-height: 32px;
        font-size: 24px;
        font-weight: 500;
    }

    small {
        font-size: 16px;
        line-height: 24px;
    }

    button,
    .button {
        color: white;
        background-color: $primary;
        padding: 8px 24px;
        text-decoration: none;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        border-radius: 3px;
        box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.13);
        border: none;
        transition: all 300ms;

        &:hover,
        &:focus,
        &:active {
            background-color: darken($primary, 10%);
            outline: none;
            color: white;
        }
    }

    textarea {
        border-radius: 8px;
        min-height: 124px;
    }

    blockquote {
        background-color: #f8f9fd;
        padding: 40px;
        border-radius: 10px;
        border: none;
        flex-grow: 1;
        text-align: left;

        & > * {
            &:last-child {
                margin-bottom: 0;
            }
        }

        p {
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            color: #747b8c;
        }
    }

    .btn-reaction {
        padding: 4px;
        background-color: white;
        border-radius: 8px;
        width: 35px;
        height: 32px;

        &:hover,
        &:active,
        &:focus {
            background-color: $primary;
        }

        &[disabled] {
            opacity: 0.5;

            &:hover,
            &:active,
            &:focus {
                background-color: white;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        img {
            max-width: 20px;
        }
    }

    .wrotevote-logo {
        text-align: center;
        margin-bottom: 64px;

        img {
            max-width: 430px;
        }

        &--small {
            img {
                max-width: 220px;
            }
        }
    }

    .peek {
        max-width: 90px;
    }
}
