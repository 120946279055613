.table {
    td,
    th {
        vertical-align: middle;

        &.td-tight {
            width: 1px;
            white-space: nowrap;
        }
    }
}
