html,
body,
#root {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    background-color: $blue;
}

div {
    position: relative;
}

.gap-3 {
    gap: map-get($spacers, 3);
}
