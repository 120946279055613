.carousel-indicators {
    position: relative;

    li {
        background-color: $primary;
        border-radius: 10px;
        border-top: 0;
        border-bottom: 0;
    }
}

.carousel-inner {
    overflow: visible;

    .carousel-item {
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        position: absolute;
        transition: opacity 200ms ease-in-out;

        &:first-of-type {
            position: relative;
        }
    }

    .active {
        opacity: 1;
    }
}
