.highlighting-tags {
    display: flex;
    margin-bottom: 8px;

    &--disabled {
        opacity: 0.5;
    }
}

.highlighting-tag {
    background-color: #747b8c;
    color: white;
    text-transform: uppercase;
    border-radius: 4px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    font-size: 12px;
    border: none;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-right: 8px;

    &:enabled {
        &:hover {
            background-color: darken(#747b8c, 10%);
        }
    }

    &::before {
        content: ' ';
        width: 14px;
        height: 14px;
        border-radius: 14px;
        background-color: var(--tag-colour);
        display: inline-block;
        margin-right: 4px;
        border: 1px solid #747b8c;
    }

    &--active {
        color: #5d5d5d;
        background-color: var(--tag-colour);
    }
}
