// colours
$primary: #f0a31a;
$secondary: #abb5bf;
$blue: #697be9;

// font
$font-family-sans-serif: "museo-sans", serif;

// buttons
$btn-font-size: 12px;
$btn-border-radius: 6px;
$btn-padding-y: 7px;
$btn-padding-x: 18px;

// carousel
$carousel-control-color: $primary;
$carousel-indicator-width: 16px;
$carousel-indicator-height: 16px;
$carousel-control-opacity: 0.35;
