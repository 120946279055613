.snippet {
    width: 100%;
    padding: 30px;
    color: #635852;
    font-size: 16px;
    line-height: 24px;
    font-family: "museo-sans", serif;
    border-style: solid;
    border-width: 1px;
    text-align: left;

    & > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.snippet-primary {
    border-color: #e8d3c2;
    background-color: #fdf4eb;
}

.snippet-example {
    color: #747B8C;
    background-color: #EBF7FC;
    border-color: #C1CFE5;
}

.snippet-reply {
    background-color: #d4edda;
    border-color: #73c487;
    transition: all 200ms;

    &[type="button"] {
        &:hover,
        &:focus,
        &:active {
            background-color: darken(#d4edda, 10);
            border-color: darken(#73c487, 10);
        }
    }
}
