.btn {
    text-transform: uppercase;
    text-shadow: 0px 2px rgba(0, 0, 0, 0.15);
    color: white;

    &:disabled {
        color: white;
    }

    &:hover,
    &:focus,
    &:active {
        &:enabled {
            color: white;
        }
    }
}

.btn-primary {
    box-shadow: inset 0px -3px 0px 0px #e0901b, 0px 4px 0px 0px rgba(0, 0, 0, 0.08);
}

.btn-secondary {
    box-shadow: inset 0px -3px 0px 0px #99a3aa, 0px 4px 0px 0px rgba(0, 0, 0, 0.08);
}

.btn-link {
    color: $blue;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
        color: darken($blue, 20%) !important;
    }
}
